import React from 'react';
import styled from 'styled-components';

const StyledImg = styled.img`
  max-width: 100%;
  margin: 4vh 0;
  ${({ theme }) => theme.mq.m} {
    justify-self: center;
  };
`;

const ContactDecoration = ({ imageUrl }) => (
  <StyledImg src={imageUrl} />
);

export default ContactDecoration;
