import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.address`
  justify-self: center;
  padding: 5vh 3rem 0 3rem;
  display: grid;
  grid-gap: 2.5rem;
  text-align: center;
  h1 {
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 20px;
  }
  section {
    h2 {
      font-size: 1.5rem;
      text-transform: uppercase;
      font-weight: 700;
      color: ${({ theme }) => theme.color.gray};
      margin-bottom: 1rem;
    }
    a {
      text-decoration: none;
      font-size: 1.25rem;
      letter-spacing: 1px;
      color: ${({ theme }) => theme.color.gray};
    }
  }
`;

const StyledPhone = styled.div`
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 700;
  color: ${({ theme }) => theme.color.gray};
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.color.gray};
  }
  @media screen and (min-width: 768px) {
     a[href*="tel:"] {
       cursor: default;
       pointer-events: none;
     }
  }
`;

const Address = ({title, sections, phoneNumber}) => (
  <StyledWrapper>
    <h1>{title}</h1>
    <StyledPhone>
      <a aria-label="phone number" href={`tel:+48${phoneNumber}`}>
        {phoneNumber}
      </a>
    </StyledPhone>
    {sections.map(({mail, title: sectionTitle}) => (
      <section key={sectionTitle}>
        <h2>{sectionTitle}</h2>
        <a aria-label="email" href={`mailto:${mail}`}>{mail}</a>
      </section>
    ))}
  </StyledWrapper>
);

export default Address;
