import React from 'react';
import Layout from 'components/layout/Layout';
import Container from 'components/containers/Container';
import Grid from 'components/containers/Grid';
import Address from 'components/Address';
import ContactDecoration from 'components/ContactDecoration';
import { graphql } from "gatsby";
import SEO from "../components/Seo";

const Contact = ({ data, location }) => {
  const addressData = data.allDatoCmsContact.edges[0].node
  return (
    <Layout location={location}>
      <SEO title="CONTACT" />
      <Container size="m">
        <Grid cols="1fr 2fr" colsM="auto" justify="end">
          <Address {...addressData} />
          <ContactDecoration imageUrl="/contact.jpg" />
        </Grid>
      </Container>
    </Layout>
  );
};

export default Contact;


export const query = graphql`
  query AllContact {
    allDatoCmsContact {
        edges {
            node {
                phoneNumber
                title
                sections {
                    title
                    mail
                }
            }
        }
    }
  }
`;
